import React, { useState } from "react";
import "./App.css";

export const HIRAGANA_START = 0x3041;
export const HIRAGANA_END = 0x3096;
export const KATAKANA_START = 0x30a1;
export const KATAKANA_END = 0x30fc;
export const KATAKANA_OFFSET = KATAKANA_START - HIRAGANA_START;

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
function shuffleArray(array: Array<any>) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

interface QuestionAnswer {
  japanese: string;
  english: string;
  correct: boolean;
}

interface Question {
  letter: string;
  english: string;
  answers: QuestionAnswer[];
  answeredCorrect?: boolean;
}

interface GameStats {
  // Number of letters we're using to ask questions
  hiraletters: number;
  kataletters: number;

  usekata: boolean;
  // Total asked
  numQuestions: number;
  // Total answered correctly
  numCorrect: number;

  recentQuestions: number;
  recentCorrect: number;

  message?: string;
  messageClass?: string;
  streak: number;

  // For each letter (kata and hira) the number right in a row
  // the user has had. Drops 50% on error.  Caps at 10.
  streaks: any;

  // History
  history: Question[];
}

interface LinkButtonProps {
  label: string;
  onClick: any;
}

export function LinkButton(props: LinkButtonProps) {
  const { label, onClick } = props;
  return (
    <button className="linkbutton" onClick={onClick}>
      {label}
    </button>
  );
}

const ORDER = [
  "あ",
  "い",
  "う",
  "え",
  "お",
  "か",
  "き",
  "く",
  "け",
  "こ",
  "さ",
  "し",
  "す",
  "せ",
  "そ",
  "た",
  "ち",
  "つ",
  "て",
  "と",
  "な",
  "に",
  "ぬ",
  "ね",
  "の",
  "は",
  "ひ",
  "ふ",
  "へ",
  "ほ",
  "ま",
  "み",
  "む",
  "め",
  "も",
  "ら",
  "り",
  "る",
  "れ",
  "ろ",
  "や",
  "ゆ",
  "よ",
  "わ",
  "ゐ",
  "ゑ",
  "を",
  "ん",
  "が",
  "ぎ",
  "ぐ",
  "げ",
  "ご",
  "ざ",
  "じ",
  "ず",
  "ぜ",
  "ぞ",
  "だ",
  "ぢ",
  "づ",
  "で",
  "ど",
  "ば",
  "び",
  "ぶ",
  "べ",
  "ぼ",
  "ぱ",
  "ぴ",
  "ぷ",
  "ぺ",
  "ぽ",
  "ゔぁ",
  "ゔぃ",
  "ゔ",
  "ゔぇ",
  "ゔぉ",
];

const BASIC_ROMAJI: any = {
  あ: "a",
  い: "i",
  う: "u",
  え: "e",
  お: "o",
  か: "ka",
  き: "ki",
  く: "ku",
  け: "ke",
  こ: "ko",
  さ: "sa",
  し: "shi",
  す: "su",
  せ: "se",
  そ: "so",
  た: "ta",
  ち: "chi",
  つ: "tsu",
  て: "te",
  と: "to",
  な: "na",
  に: "ni",
  ぬ: "nu",
  ね: "ne",
  の: "no",
  は: "ha",
  ひ: "hi",
  ふ: "fu",
  へ: "he",
  ほ: "ho",
  ま: "ma",
  み: "mi",
  む: "mu",
  め: "me",
  も: "mo",
  ら: "ra",
  り: "ri",
  る: "ru",
  れ: "re",
  ろ: "ro",
  や: "ya",
  ゆ: "yu",
  よ: "yo",
  わ: "wa",
  ゐ: "wi",
  ゑ: "we",
  を: "wo",
  ん: "n",
  が: "ga",
  ぎ: "gi",
  ぐ: "gu",
  げ: "ge",
  ご: "go",
  ざ: "za",
  じ: "ji",
  ず: "zu",
  ぜ: "ze",
  ぞ: "zo",
  だ: "da",
  ぢ: "ji",
  づ: "zu",
  で: "de",
  ど: "do",
  ば: "ba",
  び: "bi",
  ぶ: "bu",
  べ: "be",
  ぼ: "bo",
  ぱ: "pa",
  ぴ: "pi",
  ぷ: "pu",
  ぺ: "pe",
  ぽ: "po",
  ゔぁ: "va",
  ゔぃ: "vi",
  ゔ: "vu",
  ゔぇ: "ve",
  ゔぉ: "vo",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BASIC_KUNREI: object = {
  a: "あ",
  i: "い",
  u: "う",
  e: "え",
  o: "お",
  k: { a: "か", i: "き", u: "く", e: "け", o: "こ" },
  s: { a: "さ", i: "し", u: "す", e: "せ", o: "そ" },
  t: { a: "た", i: "ち", u: "つ", e: "て", o: "と" },
  n: { a: "な", i: "に", u: "ぬ", e: "ね", o: "の" },
  h: { a: "は", i: "ひ", u: "ふ", e: "へ", o: "ほ" },
  m: { a: "ま", i: "み", u: "む", e: "め", o: "も" },
  y: { a: "や", u: "ゆ", o: "よ" },
  r: { a: "ら", i: "り", u: "る", e: "れ", o: "ろ" },
  w: { a: "わ", i: "ゐ", e: "ゑ", o: "を" },
  g: { a: "が", i: "ぎ", u: "ぐ", e: "げ", o: "ご" },
  z: { a: "ざ", i: "じ", u: "ず", e: "ぜ", o: "ぞ" },
  d: { a: "だ", i: "ぢ", u: "づ", e: "で", o: "ど" },
  b: { a: "ば", i: "び", u: "ぶ", e: "べ", o: "ぼ" },
  p: { a: "ぱ", i: "ぴ", u: "ぷ", e: "ぺ", o: "ぽ" },
  v: { a: "ゔぁ", i: "ゔぃ", u: "ゔ", e: "ゔぇ", o: "ゔぉ" },
};

// Goal:
//  - Show a letter
//  - Show some options, one correct

const AnswerLetter = (props: any) => {
  const { number, answer } = props;
  return (
    <span className="answerContainer">
      <div className="answerNumber">{number}</div>
      <div className="answerText">{answer.english}</div>
    </span>
  );
};

const nextQuestion = (stats: GameStats) => {
  let all = ORDER;
  let numLetters = stats.usekata ? stats.kataletters : stats.hiraletters;
  let letter = all[Math.floor(Math.random() * numLetters)];
  let answers: QuestionAnswer[] = [];
  let displayLetter = letter;

  if (stats.usekata === true) {
    const code = letter.charCodeAt(0) + KATAKANA_OFFSET;
    displayLetter = String.fromCharCode(code);
    console.log(displayLetter);
  }

  // Add the correct one
  answers.push({
    japanese: displayLetter,
    english: BASIC_ROMAJI[letter],
    correct: true,
  });

  let added: string = letter;
  while (answers.length < 4) {
    let letter = all[Math.floor(Math.random() * all.length)];
    if (added.indexOf(letter) !== -1) {
      continue;
    }

    added = added + letter;

    answers.push({
      japanese: letter,
      english: BASIC_ROMAJI[letter],
      correct: false,
    });
  }

  shuffleArray(answers);

  let question: Question = {
    letter: displayLetter,
    english: BASIC_ROMAJI[letter],
    answers,
  };

  return question;
};

const recordAnswer = (question: Question, answer: number, setStats: any) => {
  setStats((stats: GameStats) => {
    let newStats = { ...stats };

    const q = { ...question };
    q.answeredCorrect = question.answers[answer].correct;
    if (q.answeredCorrect) {
      newStats.numCorrect = newStats.numCorrect + 1;
    }
    newStats.history.push(q);

    let streakCount = newStats.streaks[question.letter] || 0;
    if (q.answeredCorrect) {
      newStats.streak = newStats.streak + 1;
      if (newStats.streak > 1) {
        newStats.message = "Correct! Streak = " + newStats.streak;
      } else {
        newStats.message = "Correct!";
      }
      newStats.messageClass = "correctMessage";

      streakCount = Math.min(10, streakCount + 1);
    } else {
      newStats.streak = 0;
      newStats.message = "Sorry, " + question.letter + " = " + question.english;
      newStats.messageClass = "incorrectMessage";

      streakCount = Math.floor(streakCount / 2);
    }
    newStats.streaks[question.letter] = streakCount;

    // Recent questions
    const lastTwenty = stats.history.slice(
      Math.max(stats.history.length - 20, 0)
    );
    newStats.recentQuestions = Math.min(20, lastTwenty.length);
    newStats.recentCorrect = lastTwenty.reduce((acc: number, q: Question) => {
      return acc + (q.answeredCorrect ? 1 : 0);
    }, 0);

    let lettercount: number = stats.usekata
      ? stats.kataletters
      : stats.hiraletters;

    if (stats.recentCorrect > 18) {
      lettercount = Math.min(lettercount + 1, 86);
    }

    if (
      stats.recentQuestions === 20 &&
      stats.recentCorrect < 15 &&
      lettercount > 5
    ) {
      lettercount = lettercount - 1;
    }

    if (stats.usekata === true) {
      newStats.kataletters = lettercount;
      window.localStorage.setItem("kataletters", lettercount.toString());
    } else {
      newStats.hiraletters = lettercount;
      window.localStorage.setItem("hiraletters", lettercount.toString());
    }

    return newStats;
  });
};

const Stats = (props: any) => {
  const { stats } = props;

  let numletters = stats.usekata ? stats.kataletters : stats.hiraletters;

  if (stats.history.length === 0) {
    return <div>No answers yet.</div>;
  }

  return (
    <div>
      {Math.floor((stats.recentCorrect / stats.recentQuestions) * 100)}% of last{" "}
      {stats.recentQuestions} correct. Using {numletters} characters.
    </div>
  );
};

const App = () => {
  let defaultStats: GameStats = {
    hiraletters: parseInt(window.localStorage.getItem("hiraletters") || "5"),
    kataletters: parseInt(window.localStorage.getItem("kataletters") || "5"),
    history: new Array<Question>(),
    numQuestions: 0,
    numCorrect: 0,
    recentQuestions: 0,
    recentCorrect: 0,
    message: undefined,
    messageClass: "",
    streak: 0,
    streaks: {},
    usekata: true,
  };

  const [stats, setStats] = useState(defaultStats);
  const [question, setQuestion] = useState({
    letter: "あ",
    english: "a",
    answers: new Array<QuestionAnswer>(),
  });

  // Create a reference to the question so we can access it
  // in the keydown handler.
  const questionRef: any = React.useRef();
  questionRef.current = question;

  const setStatsRef: any = React.useRef();
  setStatsRef.current = setStats;

  const statsRef: any = React.useRef();
  statsRef.current = stats;

  if (question.answers.length === 0) {
    document.addEventListener("keydown", (event: any) => {
      const question = questionRef.current;
      const stats = statsRef.current;

      let code = parseInt(event.key) - 1;
      if (code >= 0 && code <= 3) {
        recordAnswer(question, code, setStats);
        setQuestion(() => nextQuestion(stats));
      }
    });

    const q = nextQuestion(stats);
    setQuestion(() => q);
  }

  const clickAnswer = (code: number) => {
    recordAnswer(question, code, setStats);
    setQuestion(() => nextQuestion(stats));
  };

  const setKataMode = (usekata: boolean) => {
    setStats((stats: GameStats) => {
      let newStats = {
        ...stats,
        usekata,
        streak: 0,
        history: new Array<Question>(),
      };
      setTimeout(() => {
        const q = nextQuestion(newStats);
        setQuestion(() => q);
      }, 5);
      return newStats;
    });
  };

  return (
    <div className="App">
      <Stats stats={stats} setStats={setStats} />
      <span className="bigletter">{question.letter}</span>
      {question.answers.length === 4 && (
        <table className="answerTable">
          <tbody>
            <tr className="answerTableRow">
              <td className="answerCell" onClick={() => clickAnswer(0)}>
                <AnswerLetter number={1} answer={question.answers[0]} />
              </td>
              <td className="answerCell" onClick={() => clickAnswer(1)}>
                <AnswerLetter number={2} answer={question.answers[1]} />
              </td>
              <td className="answerCell" onClick={() => clickAnswer(2)}>
                <AnswerLetter number={3} answer={question.answers[2]} />
              </td>
              <td className="answerCell" onClick={() => clickAnswer(3)}>
                <AnswerLetter number={4} answer={question.answers[3]} />
              </td>
            </tr>
            <tr>
              <td colSpan={4} align="center">
                <div className={stats.messageClass ? stats.messageClass : ""}>
                  {stats.message ? stats.message : ""}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <div>
        <br />
        <br />
        {stats.usekata ? (
          <LinkButton
            label="Switch to Hiragana"
            onClick={() => setKataMode(false)}
          />
        ) : (
          <LinkButton
            label="Switch to Katakana"
            onClick={() => setKataMode(true)}
          />
        )}
        <br />
      </div>

      <div className="about">
        v1.1, by @stevex
        <br />
      </div>
      <div className="hint">Use keyboard numbers to answer</div>
    </div>
  );
};

export default App;
